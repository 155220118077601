import React from "react"
import { ContactForm, Layout } from "../../../components"
import { Helmet } from "react-helmet"
import {
  ContainerCustom,
  Content,
  Cubes,
  H2,
  Medailon,
} from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/borgers/logo.jpg"
import Lukas from "../../../images/team/mini/lukas.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import OgImage from "../../../images/reference/borgers/og-image.png"
import Uvodka from "../../../images/reference/borgers/uvodka.jpg"
import Analysis from "../../../images/reference/borgers/graph.svg"
import JobIcon from "../../../images/reference/borgers/two_penguins.svg"
import EmployeeIcon from "../../../images/reference/borgers/chat_bubble.svg"
import Adam from "../../../images/team/mini/adam.jpg"
import Eliska from "../../../images/team/mini/eliska.jpg"
import Kratkodoba from "../../../images/reference/borgers/borgers-screen-post1.jpg"
import Dlouhodoba from "../../../images/reference/borgers/borgers-screen-post2.jpg"
import MindMapa from "../../../images/reference/borgers/mapa.png"
import NegativeMarginWrapper from "@igloonet-web/shared-ui/components/negative-margin-wrapper"

const Borgers: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Výzkum, EVP a náborové kampaně pro Borgers | igloonet.cz</title>
        <meta
          name="description"
          content="Široká analýza a kvalitativní výzkum | Nastavení Employer Value Proposition pro vekou výrobní firmu | Dlouhodobá komunikace i okamžitý nábor"
        />
        <meta
          property="og:title"
          content="Výzkum, EVP a náborové kampaně pro Borgers"
        />
        <meta property="og:image" content={OgImage} />
      </Helmet>
      <ReferenceHeader
        companyName="BORGERS"
        heading="Náborové kampaně –&nbsp;od výzkumu přes definici EVP až po nastavení a&nbsp;správu"
        date="prosinec 2020 –&nbsp;září 2021"
      />
      <ReferenceImageText
        right
        img={Uvodka}
        eager
        alt=""
        logo={Logo}
        logoAlt="logo Borgers"
        link="//borgers.cz/"
      >
        <Cubes
          bgWhite
          data={[
            {
              description: "Široká analýza a kvalitativní výzkum",
              image: Analysis,
            },
            {
              description: "Nastavení EVP pro velkou výrobní firmu",
              image: EmployeeIcon,
            },
            {
              description: "Okamžitý nábor i dlouhodobá komunikace",
              image: JobIcon,
            },
          ]}
        />
        <h2 className="pt-5">Zadání / cíl:</h2>
        <p>
          Borgers, působící v&nbsp;oblasti automotive, chce výrazně navýšit
          počet kmenových zaměstnanců (oproti těm agenturním) a&nbsp;nastartovat
          procesy pro posílení náboru v&nbsp;následujících letech.
        </p>
      </ReferenceImageText>
      <ReferenceImageText>
        <H2>Komunikace na reálných základech</H2>
        <p>
          Náborové kampaně u&nbsp;výrobních firem nejsou tak jednoduché, jak se
          na první pohled zdá. Není možné je jen tak bezhlavě spustit
          a&nbsp;poté kontrolovat, kolik životopisů za den přijde.
        </p>
        <p>
          Výrobní společnosti jsou specifické v&nbsp;tom, že se potýkají
          s&nbsp;otázkami bezpečnosti, pracovního prostředí a&nbsp;agenturních
          pracovníků. Do toho je potřeba počítat s&nbsp;obdobím, kdy se firmě
          daří více (nabírají se noví zaměstnanci), nebo naopak hůře (dochází
          k&nbsp;propouštění, plánují se odstávky). Kombinací těchto situací
          vzniká potenciálně výbušný mix, který často ovlivňuje vnímání
          společnosti lidmi z&nbsp;jejího okolí.
        </p>

        <Content>
          <strong>Na začátek bylo pro nás proto nezbytné zjistit:</strong>
          <ol>
            <li>
              jaké je vnímání firmy v&nbsp;okolí a&nbsp;mezi stávajícími
              zaměstnanci;
            </li>
            <li>jak vypadá srovnání s&nbsp;konkurencí;</li>
            <li>co jsou silné body, na kterých můžeme komunikaci postavit;</li>
            <li>na co si naopak v&nbsp;komunikaci dát pozor;</li>
            <li>
              jak vypadá cesta potenciálního kandidáta –&nbsp;od prvního
              kontaktu se společností až po podepsání pracovní smlouvy.
            </li>
          </ol>
        </Content>
      </ReferenceImageText>
      <Medailon
        personName="Adam Šilhan"
        position="vedoucí obchodu a rozvoje"
        img={Adam}
      >
        <p>
          Abychom si v&nbsp;komplexní spolupráci vytvořili co nejreálnější
          obrázek vnitřního i&nbsp;vnějšího vnímání společnosti, zvolili jsme
          široké spektrum metod –&nbsp;od desk research a&nbsp;interních
          workshopů přes polootevřené hloubkové rozhovory až po širokou analýzu
          zmínek a&nbsp;konkurence.
        </p>
      </Medailon>

      <NegativeMarginWrapper>
        <ReferenceImageText>
          <H2>Employer Value Proposition jako ideální páteř komunikace</H2>
          <p>
            Je mnoho způsobů, jak Employer Value Proposition (zkráceně EVP),
            tedy v&nbsp;základu jakousi hodnotovou nabídku zaměstnavatele,
            nastavit. My se zaměřujeme na to, aby EVP nebylo pouze „wishful
            thinking“ cvičení, ale aby opravdu reflektovalo realitu a&nbsp;HR se
            tak o&nbsp;něj mohlo opřít. Proto je důležité ho nejen správně
            nastavit, ale i&nbsp;průběžně aktualizovat.
          </p>
          <strong>EVP zpracováváme jako syntézu několika oblastí:</strong>
          <ul>
            <li>vnímání firmy vnějším okolím;</li>
            <li>aktivity konkurence;</li>
            <li>vnímání firmy managementem a&nbsp;HR;</li>
            <li>vnímání firmy samotnými řadovými pracovníky.</li>
          </ul>
        </ReferenceImageText>
      </NegativeMarginWrapper>

      <Medailon
        personName="Eliška Hypšmanová"
        position="marketingová specialistka"
        img={Eliska}
      >
        <p>
          Pro výzkum mezi zaměstnanci jsme zvolili polostrukturované hloubkové
          rozhovory, umožňující se držet jasného postupu, ale s&nbsp;velkou
          mírou volnosti odbočit. Na kvalitativní výzkum, pod který hloubkové
          rozhovory spadají, je pak vždy dobré navázat také kvantitou, ověřující
          sílu či důležitost nových zjištění a&nbsp;hypotéz.
        </p>
      </Medailon>

      <NegativeMarginWrapper>
        <ReferenceImageText>
          <p>
            EVP vytváříme podle našeho konceptu C5 (Culture, Career, Collective,
            Compensation, Content). Víme tak, v&nbsp;kterých oblastech má klient
            silné a&nbsp;slabé stránky a&nbsp;jak s&nbsp;nimi dál pracovat.
            V&nbsp;rámci dlouhodobé komunikace můžeme koncept C5 využít jako
            hlavní témata, u&nbsp;krátkodobé kampaně pomůžou s&nbsp;jasným
            odlišením.
          </p>
        </ReferenceImageText>
      </NegativeMarginWrapper>

      <ReferenceImageText
        right
        img={MindMapa}
        alt=""
        title="Ukázka mindmapy témat k obsahové strategii."
      >
        <H2>Dlouhodobá komunikace</H2>
        <strong>Na novou EVP už jsme mohli navázat akčním plánem:</strong>
        <ul>
          <li>vytvořením obsahového plánu;</li>
          <li>
            rozpracováním ukázkového redakčního plánu (první 3&nbsp;příspěvky
            jsme pro Borgers připravili sami);
          </li>
          <li>
            přípravou propagační struktury pro příspěvky; navržením vizuálních
            prvků a&nbsp;šablon pro bannery tak, aby byly příspěvky Borgers na
            první pohled rozpoznatelné;
          </li>
          <li>
            školením práce se sociálními sítěmi –&nbsp;jak uvést obsahový plán
            v&nbsp;život;
          </li>
          <li>
            s doporučeními k&nbsp;úpravám náborových stránek, aby lépe předávaly
            hodnoty společnosti a&nbsp;odpovídaly na otázky kandidátů.
          </li>
        </ul>
      </ReferenceImageText>
      <ReferenceImageText>
        <H2>Krátkodobá komunikace</H2>
        <p>
          Binet Field pravidlo o&nbsp;vyvážení dlouhodobé a&nbsp;krátkodobé
          komunikace platí v&nbsp;HR marketingu stejně jako v retailu. Navíc
          vzhledem k&nbsp;tomu, jak velké životní rozhodnutí je nastoupit někam
          do nové práce, „nákupní proces“ nového zaměstnance je zpravidla
          výrazně delší.
        </p>
        <p>
          Krátkodobá komunikace pracuje s&nbsp;tím, že potenciální kandidáti
          budou postupně přicházet do styku s&nbsp;tou dlouhodobou. Tudíž není
          nutné do kampaně „dostat všechno“.
        </p>
      </ReferenceImageText>
      <Medailon
        noCover
        personName="Lukáš Havlík"
        position="vedoucí marketingu"
        img={Lukas}
      >
        <p>
          V praxi jde pak o&nbsp;to, abychom správně zkombinovali velikost
          a&nbsp;rozdělení mediálního rozpočtu mezi jednotlivé kanály, šíři
          cílení a&nbsp;počet, hloubku a&nbsp;srozumitelnost komunikovaných
          témat.
        </p>
        <p>
          Na základech nové EVP nastavujeme krátkodobé kampaně pro okamžité
          obsazení volných pozic, ale také dlouhodobou, hodnotovou propagaci.
          Díky ní roste známost značky zaměstnavatele v&nbsp;nastavených
          konotacích, díky čemuž dokážeme dlouhodobě nabrat více kvalitních lidí
          a&nbsp;za nižší vstupní náklady
        </p>
      </Medailon>

      <div className="pb-5 mb-3">
        <ContainerCustom className="text-center">
          <img
            src={Dlouhodoba}
            alt=""
            width={500}
            height={740}
            className="mt-2 mr-2 img-fluid"
            loading="lazy"
          />
          <img
            src={Kratkodoba}
            alt=""
            width={500}
            height={740}
            className="mt-2 img-fluid"
            loading="lazy"
          />
        </ContainerCustom>
        <p className="text-center pt-3">
          Ukázka dlouhodobé a&nbsp;krátkodobé komunikace (příspěvky z&nbsp;dubna
          2021)
        </p>
      </div>

      <ReferenceImageText>
        <Content>
          <H2>Co to Borgers přineslo</H2>
          <ul>
            <li>
              Nestranný pohled z&nbsp;vnějšího prostředí na Borgers jako značku
              zaměstnavatele – jak ji vnímají lidé z&nbsp;okolí i&nbsp;samotní
              zaměstnanci;
            </li>
            <li>
              pojmenování silných stránek, na kterých mohou Borgers stavět,
              i&nbsp;míst ke zlepšení či k&nbsp;lepší komunikaci;
            </li>
            <li>
              zpracovanou dlouhodobou komunikaci, kterou převzal a&nbsp;dále
              rozvíjí interní tým. Navíc díky níž se postupně zlepšuje vnímání
              Borgers mezi potenciálními zaměstnanci.
            </li>
          </ul>
        </Content>
      </ReferenceImageText>

      <ContactForm contact="adam" />
    </Layout>
  )
}

export default Borgers
